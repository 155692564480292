<template>
  <div>
    <h3 style="display: inline-flex">心狗个人健康平台</h3>
    <el-menu class="site-navbar__menu site-navbar__menu--right" mode="horizontal">

      <el-menu-item index="1">
        <a @click="$router.push({ name: 'myhealthHome' })" target="_blank">首页</a>
      </el-menu-item>
      <el-menu-item index="2">
        <a @click="$router.push({ name: 'myh_history' })" target="_blank">历史/趋势数据</a>
      </el-menu-item>
      <el-menu-item index="3">
        <a @click="$router.push({ name: 'PsInfo' })" target="_blank">个人信息表</a>
      </el-menu-item>
      <el-menu-item class="site-navbar__avatar" index="4">
        <el-dropdown :show-timeout="0" placement="bottom">
          <span class="el-dropdown-link">
            <img src="~@/assets/img/touxiang.jpg" :alt="userName">{{ userName }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="$router.push({ name: 'AccountSetting' })">账户信息</el-dropdown-item>
            <el-dropdown-item @click.native="$router.push({ name: 'AccountSetting' })">修改密码</el-dropdown-item>
            <el-dropdown-item @click.native="logoutHandle()">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-menu-item>
    </el-menu>
    <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password>
  </div>
</template>

<script>
// import { clearLoginInfo } from '@/utils'
import UpdatePassword from '../main-navbar-update-password'
export default {
  data() {
    return {
      updatePassowrdVisible: false
    }
  },
  components: {
    UpdatePassword
  },
  computed: {
    // userName: {
    //   get() { return this.$store.state.module.moduleName }
    // },
    userName: {
        get () { return this.$store.state.personal_module.username },
        set (val) {this.$store.commit('personal_module/updateUsername',val)}
      },
    menuActiveName: {
      get () { return this.$store.state.common.menuActiveName },
      set (val) { this.$store.commit('common/updateMenuActiveName', val) }
    },
    mainTabs: {
      get () { return this.$store.state.common.mainTabs },
      set (val) { this.$store.commit('common/updateMainTabs', val) }
    },
    mainTabsActiveName: {
      get() {return this.$store.state.common.mainTabsActiveName;},
      set(val) {this.$store.commit("common/updateMainTabsActiveName", val);},
    },
    menuList: {
      get() { return this.$store.state.common.menuList; },
      set(val) { this.$store.commit("common/updateMenuList", val); },
    },
  },
  methods: {
    // 修改密码
    updatePasswordHandle() {
      this.updatePassowrdVisible = true
      this.$nextTick(() => {
        this.$refs.updatePassowrd.init()
      })
    },
    // 退出
    logoutHandle() {
      this.$confirm(`确定进行[退出]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // console.log(this.$store.state.common.isReLogin)
        // if(this.$store.state.common.isReLogin == true){
        //   window.location.reload()
        //   this.$store.state.common.isReLogin = false
        // }

        this.$router.replace({ name: 'login' })
        if (location.href.indexOf("#reloaded") == -1) {
          location.href = location.href + "#reloaded";
          location.reload();
        }
        this.mainTabs = []
        this.menuActiveName = ''
        this.mainTabsActiveName = ""
        this.menuList = []
        //   this.$http({
        //     url: this.$http.adornUrl('/sys/logout'),
        //     method: 'post',
        //     data: this.$http.adornData()
        //   }).then(({data}) => {
        //     if (data && data.code === 0) {
        //       console.log("退出操作")
        //       clearLoginInfo()
        // if(this.$store.state.common.isReLogin == true){
        //   window.location.reload()
        //   this.$store.state.common.isReLogin = false
        // }
        // this.$router.replace({ name: 'login' })
        //     }
        //   })
      }).catch(() => { })
    }
  }
}
</script>
